import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../i18n"
import PostgraduateComponent from "../components/PostgraduateComponent"

require("typeface-roboto")

const PostgraduatePage = () => {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState("es")
  const onChangeLanguage = () => {
    i18n.changeLanguage(language)
    if (language === "es") {
      setLanguage("en")
    } else {
      setLanguage("es")
    }
  }

  return (
    <Layout>
      <SEO title={t("title.experience")} />
      <PostgraduateComponent />
    </Layout>
  )
}

export default PostgraduatePage
